<template>
  <div class="hello">
    <div>
      <h1>{{ msg }}</h1>
      <p>
        为了提供更好的服务，我们的网站地址已更改至：
        <a
          href="https://www.timelinker.com.cn/home"
          target="_blank"
          rel="noopener"
        >www.timelinker.com.cn</a>
      </p>
      <p>请访问新网站以获取最新信息和服务，智赋云衷心感谢您的关注与支持！</p>
      <P>联系电话：400-99-23023</P> 
    </div>

    <img src="../assets//LOGO1.png" />

  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String
  },
  created() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 1920px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
p {
  color: #fff;
  font-size: 48px;
}
h1 {
  margin-top: 300px;
  color: #fff;
  font-size: 68px;
}
a {
  color: #000000;
}
img {
  height: 163px;
  width: 825px;
  position: absolute;
  bottom: 40px;
}
</style>
